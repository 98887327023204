import React from "react"

import SEO from "../../components/seo"
import Layout from "../../components/Layouts/Base"
import Sidebar from "../../components/Integrations/Sidebar"
import YoutubeEmbed from "../../components/YoutubeEmbed"

export const query = graphql`
  query GlideAppsIntegrationQuery {
    allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      skip: 0
      limit: 100
      filter: { showInSidebar: { eq: true } }
    ) {
      edges {
        node {
          slug {
            current
          }
          title
        }
      }
    }
  }
`

const IntegrationGlideAppsPage = ({ data }) => {
  const posts = data.allSanityPost.edges

  return (
    <Layout>
      <SEO
        title="Accept Payments & Subscriptions using Glide Apps with Payhere"
        description={
          "Create payment links to get paid online. Launch a one-page website with payments built in. Embed or integrate with 1000s of platforms using our SDKs or Zapier integration."
        }
        keywords={[
          `payments`,
          `online payments`,
          `card payments`,
          `recurring payments`,
          `subscriptions`,
          `recurring donations`,
          `donations`,
          `stripe`,
        ]}
      />

      <div className="max-w-5xl mx-auto px-6 py-10 lg:pb-16">
        <div className="md:grid md:grid-cols-12 md:gap-10">
          <Sidebar posts={posts} />
          <div className="md:col-span-8">
            <h1 className="text-2xl font-display text-black font-bold leading-8 md:text-4xl sm:leading-tight">
              Accept Payments & Subscriptions using Glide Apps with Payhere
            </h1>

            <div className="prose mt-6">
              <YoutubeEmbed embedId="V_Yd6EbESjc" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IntegrationGlideAppsPage
